.dateRangeInput {
        :global {
            .ant-picker-input > input {
                width: 72px;
            }

            .ant-picker-active-bar {
                display: none;
            }
        }
    }