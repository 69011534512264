$balance: #1cd900;
$island: #c8e0ca;
$border: rgba(5, 5, 5, 0.06);
$selected: #f8bc48;
$dot: rgba(0, 0, 0, 0.25);
$primary: #f8bc48;
$greenGradient: linear-gradient(0.5turn, rgb(128, 186, 67), rgb(121, 198, 69), rgb(104 157 65));

// закоментить или удалить нижнюю
// $island: #ccc;
// $primary: #ccc;
// $balance: #ccc;
// $selected: #ccc;
// $greenGradient: #ccc;
