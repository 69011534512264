@import "src/styles/buttons.module";
@import "src/styles/form.module";
@import "src/styles/containers.module";

.registerForm {
    @extend .authForm;
    max-width: 320px;

    .pageTitle {
        //margin-bottom: 20px;
        .title {
            color: black;
            font-weight: 500;
        }

        .subtitle {
            color: #ffffff;
        }
    }

    label[for="agreement"] {
        color: rgba(148, 148, 148, 1) !important;
    }

    .codeInputContainer {
        margin-block: 10px;

        :global {
            .ant-input:disabled {
                background-color: #f5f5f5 !important;
            }

            .ant-form-item-label {
                left: 210px;
                position: absolute;
                width: 200px;
            }

            .ant-form-item-label label {
                text-align: left;
                color: rgba(148, 148, 148, 1) !important;
            }
        }
    }

    .agreementInputContainer {
        :global {

            .ant-form-item-control-input-content {
                flex: unset;
            }

            .ant-form-item-row {
                flex-direction: row-reverse !important;
            }

            .ant-form-item-required::after {
                display: none !important;
            }

            .ant-form-item-control {
                //width: unset !important;
            }

            .ant-form-item-label label {
                text-align: left;
            }

            .ant-row {
                display: grid;
                /* direction: ltr; */
                direction: revert;
                grid-template-columns: 16px auto;
                gap: 5px;
            }

            .ant-row .ant-col:last-child {
                order: -1;
            }

            .ant-form-item-has-error {
                .ant-form-item-explain-error {
                    display: none;
                }

                .ant-checkbox-inner {
                    border-color: #ff4d4f;
                }
            }
        }
    }

    .PhoneItem {
        :global {
            .ant-form-item-control-input-content {
                display: flex;
                gap: 5px;
            }
        }
    }
}