@import 'src/styles/components.module.scss';
@import 'src/styles/colors.module';
@import 'src/styles/text.module.scss';

.drawerWrapper {
    :global {
        .ant-drawer-content-wrapper {
            display: none;
        }
    }
}

.requestPageContainer {
    width: 100%;

    .formContainer {
        width: 100%;
        display: flex;
        justify-content: center;

        .formItemsContainer {
            padding-block: 10px;

            .formItem {
            }
        }

        .deliveryTypeSelect {
            @extend .select;
        }
    }

    .requestTableContainer {
        padding: 5px 15px;

        .tableCheckbox {
            :global {
                .ant-checkbox-inner {
                    border-color: #ff4d4f;
                    position: relative;
                    z-index: 9999;
                }
            }
        }

        .tableFooter {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
        }

        .requestsTable {
            :global {
                .ant-table-row.ant-table-row-selected > .ant-table-cell {
                    background-color: $selected !important;
                }
            }
        }
    }
}

.requestDetail {
    display: grid;
    //padding: 10px;
    gap: 10px;
    justify-content: center;
    min-height: 50px;

    .detailRow {
        display: flex;
        gap: 10px;

        .detailItem {
            .detailItemLabel {
                font-weight: 300;
                font-size: 10px;
                line-height: 24px;
                color: rgba(0, 0, 0, 0.35);
            }

            .detailItemValue {
                background: white;
                border: 1px solid rgba(200, 224, 202, 1);
                border-radius: 9px;
                padding: 5px 15px;
                text-align: center;
            }
        }
    }
}
