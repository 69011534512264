.w260 {
    width: 260px;
}

:global .App:has(:local(.loginBackground)) {
    background-image: url('/assets/background.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: -23vh;
}

.loginBackground {
    background: center 10% url('/assets/top_title.svg'), 50% 90% url('/assets/bottom_title.svg');
    background-size: auto;
    background-repeat: no-repeat;
}

:global .ant-col:has(:local(.registerForm)) {
    top: -20px;
}