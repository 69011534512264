.authForm {
    :global {
        .ant-input, .ant-input-password {
            border-radius: 2px;
        }

        .ant-form-item-required::before {
            display: none !important;
        }

        .ant-form-item-label {
            padding: 0 !important;
        }

        .ant-typography {
            margin: 0 !important;
        }

        .ant-typography-secondary {
            line-height: 1.2;
        }

        .ant-input:not([type="password"]), .ant-input-affix-wrapper {
            border-radius: 10px !important;
            height: 47px !important;
        }

        label {
            font-weight: 500 !important;
            font-size: 10px !important;
            line-height: 24px !important;
            text-align: center;
            color: #616161 !important;
            left: 10px !important;
        }

        .ant-form-item-has-error .ant-input:not([type="password"]) {
            border-color: #ff4d4f !important;
        }

        .ant-form-item-has-error .ant-input:not([type="password"])::placeholder {
            color: #ff4d4f !important;
        }
    }
}