@import "src/styles/buttons.module";
@import "src/styles/form.module";
@import "src/styles/containers.module";

.loginFormContainer {
    box-shadow: none !important;
    display: flex;
    flex-direction: column;

    .loginFormItems {
        gap: 20px;
    }

    .loginForm {
        @extend .authForm;

        :global {
            .ant-form-item-label {
                padding: 0 !important;
            }
        }

        .resetButton {
            position: absolute;
            bottom: 32px;
            left: 284px;
        }

        .resetBtn:hover, .resetBtn {
            background: transparent;
            color: rgba(81, 89, 255, 1);
        }
    }
}