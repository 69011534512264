@import "src/styles/colors.module";

.formContainer {
    width: 100%;
    padding-inline: 15px;
    padding-bottom: 10px;

    .island {
        background: $island;
        border-radius: 10px;
        padding: 20px 10px;
    }

    .mapContainer {
        display: flex;
        flex-direction: column;
        min-width: 350px;
        min-height: 500px;
        width: 100%;
        gap: 8px;
        .map {
            min-height: 400px;
            height: 70vh;
        }
    }
}