@import "src/styles/components.module.scss";
@import "src/styles/colors.module";

.packageCheckPageContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 400px auto;
    border: 1px solid $border;
    grid-gap: 1px;
    background: $border;

    & > div {
        background-color: #f6f6f6;
    }

    .packageDetail {
        max-height: 80vh;
        overflow: auto;

        .packageDetailContent {
            padding: 16px 24px;

            :global {
                .ant-steps-item-title {
                    color: #3b3b3b !important;
                }

                //.ant-steps .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot {
                //    background-color: $dot;
                //}
            }
        }

        :global {
            .ant-list-item {
                border-block-end: 1px solid rgba(5, 5, 5, 0.06);
            }
        }
    }

    .pcList {
        max-height: 80vh;
        overflow: auto;

        //&::-webkit-scrollbar {
        //    width: 5px !important;
        //}

        .pcListItem {
            .description {
                display: flex;
                justify-content: space-between;
                padding-inline: 5px;
            }

            &.selected {
                background-color: $selected;
            }

            :global {
                .ant-list-item-meta-title {
                    display: flex;
                    justify-content: start;
                    padding-inline: 5px;
                }
            }
        }
    }
}

.searchContainer {
    width: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .searchInput {
        width: 50%;
    }
}

.searchSmall {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;

    .searchInput {
        width: 50%;
    }
}