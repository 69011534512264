@import "src/styles/text.module.scss";

.pageLayout {
    min-height: 100vh;

    .menuContainer {
        .menu {
            background: $island;
            display: flex;
            padding: 10px 15px;
            //width: 100%;
            justify-content: space-between;

            .bntSection {
                align-items: center;
                justify-content: space-around;

                .btnGroup {
                    gap: 5px;

                    .getLabelBtn:not([disabled]) {
                        background-color: $selected;
                    }
                }
            }

            .userInfoSection {
                gap: 10px;
                align-items: center;

                .userInfoText {
                    text-align: start;
                    display: flex;
                    flex-direction: column;
                }

                .userIcon {
                    svg {
                        width: 30px;
                        height: 30px;
                        color: white;
                    }
                }

                .settingBtn {
                    height: auto;
                    line-height: unset;
                    padding: 4px;

                    svg {
                        width: 25px;
                        height: 25px;
                        color: white;
                    }
                }
            }
        }
    }

    .footer {
        padding: 10px 15px;
        background: $island;

        .footerContentContainer {
            display: flex;
            justify-content: space-between;

            .footerItem {
                display: flex;
                flex-direction: column;
                gap: 5px;
                align-items: start;
                text-transform: uppercase;

                a {
                    color: inherit;
                    text-decoration: inherit;
                }
            }
        }
    }

    .pageAndUserInfoRow {
        padding: 2px 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .balanceLabel {
            display: flex;
            gap: 5px
        }
    }
}

:global {
    .ant-dropdown-menu-title-content a svg {
        display: inline-flex;
        align-items: center;
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
    }
}