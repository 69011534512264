.DeleteConfirmationModal {

    .cancelBtn {
        background: rgb(247, 205, 69) !important;
        color: white !important;
        border: none !important;
    }

    .okBtn {
        background: rgb(74, 163, 84) !important;
        color: white !important;
        border: none !important;
    }

    :global {

        .ant-modal-close {
            display: none;
        }

        .ant-modal-title {
            text-transform: uppercase;
        }

        .ant-modal-footer {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin-top: 20px;
        }

        .ant-modal-footer .ant-btn {
            padding: 4px 70px;
            text-transform: uppercase;
            border-radius: 15px;
            margin: 0 !important;
        }

        .ant-modal-content {
            border-radius: 20px;
            padding-inline: 30px;
        }
    }
}