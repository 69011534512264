@import 'src/styles/colors.module.scss';
@import 'src/styles/components.module';

.requestPageContainer {
  width: 100%;

    .extraCard {
        width: fit-content;
    }

  .island {
    background: $island;
    border-radius: 10px;
    padding: 20px 10px;
  }

  .smGap {
    gap: 8px;
  }

  .formContainer {
    width: 100%;
    padding-bottom: 15px;

    .labelAlignLeft {
      label {
        justify-content: flex-end;
        width: 100%;
      }

      :global {
        .ant-form-item-required::before {
          right: 0;
          position: absolute;
        }
      }
    }

    .textAlignLeft {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .formItemWithFloatingLabel {
      :global {
        .ant-form-item-label {
          position: absolute;
          margin-left: -10px;
        }
      }
    }

    .formColsContainer {
      //display: flex;
      padding: 0 15px;
      gap: 20px;
      width: 100%;
      display: grid;
      grid-template-columns: 830px auto;

      .LeftColItems {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        //min-width: 765px;
        //max-width: 50%;

        .packageCard {
          display: grid;
          grid-template-columns: 215px 1fr 1fr 1fr 1fr 32px;
          grid-template-rows: auto;
          //background: linear-gradient(180deg, #d2d2d2 70%, #ffba5b 10%);
          background: linear-gradient(360deg, $primary 67px, $island 0, $island 100%);

          .CreateReqBtn {
            border-radius: 30px;
            border: 1px solid black;
            background: $greenGradient;
            color: black;

            span {
              z-index: 2;
              position: relative;
            }

            &:hover {
              background: linear-gradient(0.5turn, rgb(138, 201, 72), rgb(129, 211, 74), rgb(110, 166, 69));

              span {
                color: black;
              }
            }

            &::after {
              border-radius: 30px;
              border: 0 solid #000;
              content: '';
              height: 40%;
              position: absolute;
              left: 5%;
              top: 0;
              //opacity: 40%;
              width: 90%;
              z-index: 1;
              background: linear-gradient(180deg, white 5%, #ffffff38 95%);
            }
          }

          &.calculateMode {
            background: $island;
          }

          .doubleCell {
            grid-column: 2 / 4;
          }

          .resultInput:not(:placeholder-shown) {
            color: #000;
            font-weight: 700;
          }

          .calcButton {
            background: $primary;
            color: white;
          }

          .lastLineObj {
            margin-top: 20px;
            display: flex;
            align-items: end;
            color: #f0f0f0;

            :global {
              .ant-typography {
                color: #f0f0f0;
                margin-bottom: 6px;
              }
            }
          }

          .secondDoubleCell {
            grid-column: 4 / 6;
              &.alignEnd {
                  align-items: end;
                  display: flex;
              }
          }

          .doubleCell,
          .secondDoubleCell {
            button {
              width: 100%;
            }
          }

          .topLabelContainer {
            display: flex;
            flex-direction: column;
            justify-content: start;
            text-align: left;
          }

          & > div {
            //height: 32px;
            //display: flex;
            /* justify-content: center; */
            text-align: start;
          }
        }

        .orderCard {
          display: grid;
          grid-template-columns: 350px auto;
        }

        .inlineInputRow {
          display: grid;
          grid-template-columns: auto auto;

          &.withDivider {
            grid-template-columns: 1fr 1fr 32px;
          }

          &.three {
            display: grid;
            grid-template-columns: auto auto auto;
          }

          &.four {
            display: grid;
            grid-template-columns: auto auto auto auto;
          }

          .smallTopLabel {
            position: absolute;
            height: 22px;
            line-height: 22px;
            width: 60px;
            margin-top: -20px;
            margin-left: -4px;

            label {
              font-size: 10px;
              height: 22px;
              line-height: 22px;
            }
          }

          &.withCheckBox {
            grid-template-columns: auto 50px;
          }
        }
      }

      .mapContainer {
        display: flex;
        flex-direction: column;
        //max-width: 350px;
        //width: 100%;
        gap: 8px;
        //min-width: 50%;
        //width: calc(100vw - 765px - 40px);
      }
    }

    & > div {
      width: 100%;
    }

    :global {
      .ant-form-item-has-error {
        .ant-input-outlined {
          border-color: #ff4d4f;
        }
      }
    }
  }

  .requestTableContainer {
    padding: 5px 15px;
  }

  :global {
    .ant-input[disabled] {
      background-color: rgba(255, 255, 255);
    }

    .ant-select-auto-complete {
      text-align: left;
    }

    .ant-form-item-has-error .ant-checkbox-inner {
      border-color: #ff4d4f;
    }
  }
}

.mapComponentWrapper {
  height: 100%;
  width: 100%;

  position: relative;
  overflow: hidden;
}

.mapComponent {
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;

  :global {
    ymaps[class*='-balloon__content'] > ymaps {
      min-height: 140px !important;
      min-width: 250px !important;
      overflow-y: auto !important;
    }

    ymaps[class*='-copyrights-pane'] > ymaps {
      display: none !important;
    }
  }
}

:global {
  .ant-select-dropdown {
    min-width: 250px;
  }
}

.menu {
  display: flex;
  flex-direction: column;

  position: absolute;
  z-index: 99999;
  left: 10px;
  bottom: 10px;

  &.menuFullMap {
    bottom: 50px;
  }
}

:global {
  .ant-drawer {
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;

    z-index: 99999;
  }

  .ant-drawer-content-wrapper {
    max-width: 300px;

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;

      .ant-switch-checked {
        background-color: $island;
      }
    }

    .ant-drawer-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
