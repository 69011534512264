.select {
    min-width: 100px;
}

.formWithReversedStars {
    :global {
        label.ant-form-item-required {
            flex-direction: row-reverse;
        }

        label.ant-form-item-no-colon {
            &::after {
                content: "" !important;
                margin: 0 !important;
            }
        }
    }
}