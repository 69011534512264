@import "src/styles/colors.module";

.formContainer {
    width: 100%;
    padding-inline: 15px;
    padding-bottom: 10px;

    .island {
        background: $island;
        border-radius: 10px;
        padding: 20px 10px;
    }

    .contentContainer {
        padding-inline: 100px;
        font-size: 14px;
    }
}