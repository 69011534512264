@import "src/styles/colors.module.scss";

.colored {
    &.balance {
        color: $balance;
    }
}

.numberCell {
    text-align: right;
}