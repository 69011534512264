.search_form {
    margin: 30px 0 0;
    width: 95vw;
    position: sticky;
    left: 20px;

    &__search-form__client-resolution-select {
        width: 140px;
    }
}

.top_row,
.second_row {
    justify-content: space-between;
    display: flex !important;
    align-items: center;
    width: 100%;

    .actions {
        align-items: center;
    }

    @media (max-width: 1320px) {
        flex-wrap: wrap;
    }

    @media (max-width: 1130px) {
        & > div {
            flex-wrap: wrap;
        }
    }

    @media (max-width: 600px) {
        align-items: unset;
        flex-direction: column !important;
        .mainFilters {
            flex-direction: column !important;
            gap: 5px;
        }
        .actions {
            margin-top: 10px;
        }
    }
}

.row_container {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 5px 0;
}

.actionBtnsContainer {
    //width: 100%;
}

.search {
    flex: 1;
    display: flex !important;
    justify-content: space-between;
    margin-right: 16px !important;

    &_form_item {
        width: 40vw;
    }
}

.zone {
    width: 100% !important;
}

.view_button {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 0 0 6px;
    cursor: pointer;
    background: none;
    border: none;
    opacity: 0.5;
    transition: opacity 0.25s;

    &:hover {
        opacity: 1;
    }

    &:active,
    &:focus {
        border: none;
        outline: none;
    }

    img {
        margin: 0 6px 0 0;
    }
}

.create_request_button,
.requests_import_button,
.reports_button {
    margin: 0 0 0 6px;
    display: flex;
    height: 100%;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;

    &:active,
    &:focus {
        border: none;
        outline: none;
    }

    &:hover {
        background: #efefef;
        border-radius: 8px;
    }
}

.dFlex {
    display: flex;
}
