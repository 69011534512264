.greenBtn {
    text-transform: uppercase;
    background: rgba(28, 164, 89, 1);
    border-radius: 10px;
    color: white;
    border: none;
    padding: 7px 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 14px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover, &:active, &:focus, &:visited {
        background: rgb(32, 187, 101);
        color: white;
    }

    &:disabled {
        background: rgba(177, 177, 177, 1);
        cursor: not-allowed;
        color: white;
    }
}

.registerBtn {
    text-transform: uppercase;
    background: rgba(33, 153, 197, 1);
    border-radius: 10px;
    color: white;
    border: none;
    padding: 7px 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 14px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover, &:active, &:focus, &:visited {
        background: rgba(33, 153, 197, 1) !important;
        color: white !important;
    }

    &:disabled {
        background: rgba(177, 177, 177, 1);
        cursor: not-allowed;
        color: white;
    }
}